import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import TwilioChat from '../components/Rebrand/TwilioChat';

const Chat = () => {
    return (
        <>
        <Helmet>            
            <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <TwilioChat fullscreen={true}/>
        </>
    );
};

export default Chat;